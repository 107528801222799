body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**/
*::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  background-color: #f1f1f1;
}

*::-webkit-scrollbar {
  /*width: 10px;*/
  /*background-color: #f1f1f1;*/
  height: 16px;
  overflow: visible;
  width: 16px;
}

*::-webkit-scrollbar-thumb {
  border-style: solid;
  border-color: transparent;
  border-width: 4px;
  background-color: #dadce0;
  border-radius: 8px;
  box-shadow: none;
  background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover{
  background-color: #80868b;
}

*::-webkit-scrollbar-corner {
  background: transparent;
}

*::-webkit-scrollbar-track {
  box-shadow: none;
  margin: 0;
  background: transparent;
  background-clip: padding-box;
  border: solid transparent;
  border-width: 0 0 0 4px;
}

*::-webkit-scrollbar-button {
    height: 0;
    width: 0;
}

*:not(input) {
  user-select: none;
}

.link {
  text-decoration: none;
  color: initial;
}